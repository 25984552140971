var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-form", {
    attrs: {
      form: _vm.createForm,
      labelCol: {
        style: "width: 180px;text-align:right;margin-right:10px;"
      }
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex"
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "每个账户一天内最多可提现"
    }
  }, [_c("span", [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["timesofdays", {
        rules: [{
          required: true,
          message: "请配置提现次数！"
        }, {
          pattern: /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/,
          message: "请输入非负数！"
        }]
      }],
      expression: "[\n                        'timesofdays',\n                        {\n                            rules: [\n                                {\n                                    required: true,\n                                    message: '请配置提现次数！',\n                                },\n                                {\n                                    pattern:\n                                        /^[+]{0,1}(\\d+)$|^[+]{0,1}(\\d+\\.\\d+)$/,\n                                    message: '请输入非负数！',\n                                },\n                            ],\n                        },\n                    ]"
    }],
    staticClass: "marginright",
    attrs: {
      disabled: _vm.isshowbtn,
      type: "number"
    }
  })], 1), _c("span", {
    staticClass: "marginright"
  }, [_vm._v("次，且不得超过")])]), _c("a-form-item", [_c("span", [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["limitofdays", {
        rules: [{
          required: true,
          message: "请配置提现次数！"
        }, {
          pattern: /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/,
          message: "请输入非负数！"
        }, {
          validator: _vm.limitofdaysValidator,
          message: "必须大于单笔提现金额!"
        }]
      }],
      expression: "[\n                        'limitofdays',\n                        {\n                            rules: [\n                                {\n                                    required: true,\n                                    message: '请配置提现次数！',\n                                },\n                                {\n                                    pattern:\n                                        /^[+]{0,1}(\\d+)$|^[+]{0,1}(\\d+\\.\\d+)$/,\n                                    message: '请输入非负数！',\n                                },\n                                { \n                                    validator: limitofdaysValidator,\n                                    message: '必须大于单笔提现金额!',\n                                    \n                                }\n                            ],\n                        },\n                    ]"
    }],
    staticClass: "marginright",
    attrs: {
      disabled: _vm.isshowbtn,
      type: "number"
    }
  })], 1), _c("span", [_vm._v("$/天")])])], 1), _c("div", {
    staticStyle: {
      display: "flex"
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "单笔提现金额，至少"
    }
  }, [_c("span", [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["limitmoney", {
        rules: [{
          required: true,
          message: "请配置提现规则！"
        }, {
          pattern: /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/,
          message: "请输入非负数！"
        }, {
          validator: _vm.limitmoneyValidator,
          message: ""
        }]
      }],
      expression: "[\n                        'limitmoney',\n                        {\n                            rules: [\n                                {\n                                    required: true,\n                                    message: '请配置提现规则！',\n                                },\n                                {\n                                    pattern:\n                                        /^[+]{0,1}(\\d+)$|^[+]{0,1}(\\d+\\.\\d+)$/,\n                                    message: '请输入非负数！',\n                                },\n                                {\n                                    validator: limitmoneyValidator,\n                                    message: '',\n                                },\n                            ],\n                        },\n                    ]"
    }],
    staticClass: "marginright",
    attrs: {
      disabled: _vm.isshowbtn,
      type: "number"
    }
  })], 1), _c("span", {
    staticClass: "marginright"
  }, [_vm._v("$，且不得超过")])]), _c("a-form-item", [_c("span", [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["money", {
        rules: [{
          required: true,
          message: "请配置提现规则！"
        }, {
          pattern: /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/,
          message: "请输入非负数！"
        }, {
          validator: _vm.moneyValidator,
          message: ""
        }]
      }],
      expression: "[\n                        'money',\n                        {\n                            rules: [\n                                {\n                                    required: true,\n                                    message: '请配置提现规则！',\n                                },\n                                {\n                                    pattern:\n                                        /^[+]{0,1}(\\d+)$|^[+]{0,1}(\\d+\\.\\d+)$/,\n                                    message: '请输入非负数！',\n                                },\n                                {\n                                    validator: moneyValidator,\n                                    message: '',\n                                },\n                            ],\n                        },\n                    ]"
    }],
    staticClass: "marginright",
    attrs: {
      disabled: _vm.isshowbtn,
      type: "number"
    }
  })], 1), _c("span", [_vm._v("$")])])], 1), _c("div", [_c("a-form-item", {
    attrs: {
      label: "提现费用"
    }
  }, [_c("a-radio-group", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["type", {
        initialValue: 1
      }],
      expression: "['type', { initialValue: 1 }]"
    }],
    attrs: {
      disabled: _vm.isshowbtn,
      size: "large",
      name: "radioGroup"
    },
    on: {
      change: _vm.changeradio
    }
  }, [_c("a-radio", {
    attrs: {
      value: 1
    }
  }, [_vm._v("固定收费")]), _c("a-radio", {
    attrs: {
      value: 2
    }
  }, [_vm._v("非固定收费")])], 1)], 1)], 1), _vm.type === 1 ? _c("div", [_c("a-form-item", {
    attrs: {
      label: "提现时将固定收取"
    }
  }, [_c("span", [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["fixedmoney", {
        rules: [{
          required: true,
          message: "请输入提现手续费！"
        }, {
          pattern: /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/,
          message: "请输入非负数！"
        }]
      }],
      expression: "[\n                        'fixedmoney',\n                        {\n                            rules: [\n                                {\n                                    required: true,\n                                    message: '请输入提现手续费！',\n                                },\n                                {\n                                    pattern:\n                                        /^[+]{0,1}(\\d+)$|^[+]{0,1}(\\d+\\.\\d+)$/,\n                                    message: '请输入非负数！',\n                                },\n                            ],\n                        },\n                    ]"
    }],
    staticClass: "marginright",
    attrs: {
      disabled: _vm.isshowbtn,
      type: "number"
    }
  })], 1), _c("span", [_vm._v("$")])])], 1) : _vm._e(), _vm.type === 2 ? _c("div", [_c("a-form-item", {
    attrs: {
      label: "提现时将收取提现金额的"
    }
  }, [_c("span", [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["servicerate", {
        rules: [{
          required: true,
          message: "请输入提现费率！"
        }, {
          pattern: /^100$|^(\d|[1-9]\d)(\.\d+)*$/,
          message: "请输入0-100之间的数字！"
        }]
      }],
      expression: "[\n                        'servicerate',\n                        {\n                            rules: [\n                                {\n                                    required: true,\n                                    message: '请输入提现费率！',\n                                },\n                                {\n                                    pattern: /^100$|^(\\d|[1-9]\\d)(\\.\\d+)*$/,\n                                    message: '请输入0-100之间的数字！',\n                                },\n                            ],\n                        },\n                    ]"
    }],
    staticClass: "marginright",
    attrs: {
      disabled: _vm.isshowbtn,
      type: "number"
    }
  })], 1), _c("span", [_vm._v("%")])]), _c("a-form-item", {
    attrs: {
      label: "最低收费"
    }
  }, [_c("span", [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["minservicecharge", {
        rules: [{
          validator: _vm.feesValidator,
          message: "最高收费要大于最低收费"
        }, {
          pattern: /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/,
          message: "请输入非负数！"
        }]
      }],
      expression: "[\n                        'minservicecharge',\n                        {\n                            rules: [\n                                {\n                                    validator: feesValidator,\n                                    message: '最高收费要大于最低收费',\n                                },\n                                {\n                                    pattern:\n                                        /^[+]{0,1}(\\d+)$|^[+]{0,1}(\\d+\\.\\d+)$/,\n                                    message: '请输入非负数！',\n                                },\n                            ],\n                        },\n                    ]"
    }],
    staticClass: "marginright",
    attrs: {
      disabled: _vm.isshowbtn,
      type: "number"
    }
  })], 1), _c("span", [_vm._v("$")])]), _c("a-form-item", {
    attrs: {
      label: "最高收费"
    }
  }, [_c("span", [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["maxservicecharge", {
        rules: [{
          validator: _vm.feesValidator,
          message: "最高收费要大于最低收费"
        }, {
          pattern: /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/,
          message: "请输入非负数！"
        }]
      }],
      expression: "[\n                        'maxservicecharge',\n                        {\n                            rules: [\n                                {\n                                    validator: feesValidator,\n                                    message: '最高收费要大于最低收费',\n                                },\n                                {\n                                    pattern:\n                                        /^[+]{0,1}(\\d+)$|^[+]{0,1}(\\d+\\.\\d+)$/,\n                                    message: '请输入非负数！',\n                                },\n                            ],\n                        },\n                    ]"
    }],
    staticClass: "marginright",
    attrs: {
      disabled: _vm.isshowbtn,
      type: "number"
    }
  })], 1), _c("span", [_vm._v("$")]), _c("div", {
    staticClass: "fees"
  }, [_vm._v("注：没有最低、最高收费，可不设置")])])], 1) : _vm._e(), _c("div", {
    staticClass: "combinbtn"
  }, [_c("a-button", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isshowbtn,
      expression: "!isshowbtn"
    }],
    staticStyle: {
      border: "none",
      "margin-right": "26px",
      "background-color": "#f5f5f5"
    },
    attrs: {
      size: "large"
    },
    on: {
      click: function click($event) {
        return _vm.handlecancel();
      }
    }
  }, [_vm._v("取消")]), _c("a-button", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isshowbtn,
      expression: "isshowbtn"
    }, {
      name: "hasPermission",
      rawName: "v-hasPermission",
      value: ["generalconfigurationwithdraw:btn:edit"],
      expression: "['generalconfigurationwithdraw:btn:edit']"
    }],
    attrs: {
      type: "primary",
      size: "large"
    },
    on: {
      click: function click($event) {
        return _vm.handleupdate();
      }
    }
  }, [_vm._v("修改")]), _c("a-button", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isshowbtn,
      expression: "!isshowbtn"
    }, {
      name: "hasPermission",
      rawName: "v-hasPermission",
      value: ["generalconfigurationwithdraw:btn:submit"],
      expression: "['generalconfigurationwithdraw:btn:submit']"
    }],
    attrs: {
      type: "primary",
      size: "large"
    },
    on: {
      click: function click($event) {
        return _vm.handleAdd();
      }
    }
  }, [_vm._v("确定")])], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };