import Withdrawrules from "./components/withdrawrules";
import Sellerrules from "./components/sellerrules";
import Buyerrules from "./components/buyerrules";
export default {
  name: '',
  // 定义属性
  data: function data() {
    return {
      defaultIndex: 0,
      titles: [{
        id: 0,
        name: "提现规则"
      }, {
        id: 1,
        name: "买家规则"
      }, {
        id: 2,
        name: "卖家规则"
      }]
    };
  },
  // 方法集合
  methods: {
    handleClick: function handleClick(e) {
      this.defaultIndex = e.id;
    }
  },
  components: {
    Withdrawrules: Withdrawrules,
    Sellerrules: Sellerrules,
    Buyerrules: Buyerrules
  },
  // 计算属性，会监听依赖属性值随之变化
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created: function created() {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted: function mounted() {}
};