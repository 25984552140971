var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-form", {
    attrs: {
      form: _vm.createForm,
      labelCol: {
        style: "width: 100px;text-align:right;margin-right:10px;"
      }
    }
  }, [_c("div", [_c("div", {
    staticClass: "title",
    staticStyle: {
      "margin-bottom": "24px"
    }
  }, [_vm._v("收货时长")]), _c("a-form-item", {
    attrs: {
      label: "买家收货时间"
    }
  }, [_c("span", [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["receivtime", {
        rules: [{
          required: true,
          message: "请填写买家收货时长！"
        }, {
          pattern: /^[1-9]\d*$/,
          message: "请输入正整数！"
        }]
      }],
      expression: "[\n                        'receivtime',\n                        {\n                            rules: [\n                                {\n                                    required: true,\n                                    message: '请填写买家收货时长！',\n                                },\n                                {\n                                    pattern:\n                                            /^[1-9]\\d*$/,\n                                        message: '请输入正整数！',\n                                },\n                            ],\n                        },\n                    ]"
    }],
    staticClass: "marginright",
    attrs: {
      disabled: _vm.isshowbtn,
      type: "number"
    }
  })], 1), _c("span", {
    staticClass: "marginright"
  }, [_vm._v("小时内，逾期未收货，平台将自动收货")]), _c("div", {
    staticClass: "fees"
  }, [_vm._v(" 注：周末和法定节假日期间，更新时间依据房间时间往后顺延 ")])])], 1), _c("hr", {
    staticStyle: {
      "margin-bottom": "24px",
      height: "1px",
      border: "none",
      background: "#EFEFEF"
    }
  }), _c("div", [_c("div", {
    staticClass: "title",
    staticStyle: {
      "margin-bottom": "24px"
    }
  }, [_vm._v("收单手续费 (Processing Fee)")]), _c("div", {
    staticClass: "title_2"
  }, [_vm._v("买家在发起支付时，需要支付相应的手续费")]), _c("div", [_c("a-form-item", {
    attrs: {
      label: "支付金额的"
    }
  }, [_c("span", [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["servicerate", {
        rules: [{
          required: true,
          message: "请配置收单手续费！"
        }, {
          pattern: /^100$|^(\d|[1-9]\d)(\.\d+)*$/,
          message: "请输入0-100之间的数字！"
        }]
      }],
      expression: "[\n                            'servicerate',\n                            {\n                                rules: [\n                                    {\n                                        required: true,\n                                        message: '请配置收单手续费！',\n                                    },\n                                    {\n                                        pattern:\n                                            /^100$|^(\\d|[1-9]\\d)(\\.\\d+)*$/,\n                                        message: '请输入0-100之间的数字！',\n                                    },\n                                ],\n                            },\n                        ]"
    }],
    staticClass: "marginright",
    attrs: {
      disabled: _vm.isshowbtn,
      type: "number"
    }
  })], 1), _c("span", [_vm._v("%")])]), _c("a-form-item", {
    attrs: {
      label: "最低收费"
    }
  }, [_c("span", [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["minservicecharge", {
        rules: [{
          pattern: /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/,
          message: "请输入非负数！"
        }, {
          validator: _vm.feesValidator,
          message: "最高收费要大于最低收费"
        }]
      }],
      expression: "[\n                            'minservicecharge',\n                            {\n                                rules: [\n                                    {\n                                        pattern:\n                                            /^[+]{0,1}(\\d+)$|^[+]{0,1}(\\d+\\.\\d+)$/,\n                                        message: '请输入非负数！',\n                                    },\n                                    {\n                                        validator: feesValidator,\n                                        message: '最高收费要大于最低收费',\n                                    },\n                                ],\n                            },\n                        ]"
    }],
    staticClass: "marginright",
    attrs: {
      disabled: _vm.isshowbtn,
      type: "number"
    }
  })], 1), _c("span", [_vm._v("$")])]), _c("a-form-item", {
    attrs: {
      label: "最高收费"
    }
  }, [_c("span", [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["maxservicecharge", {
        rules: [{
          pattern: /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/,
          message: "请输入非负数！"
        }, {
          validator: _vm.feesValidator,
          message: "最高收费要大于最低收费"
        }]
      }],
      expression: "[\n                            'maxservicecharge',\n                            {\n                                rules: [\n                                    {\n                                        pattern:\n                                            /^[+]{0,1}(\\d+)$|^[+]{0,1}(\\d+\\.\\d+)$/,\n                                        message: '请输入非负数！',\n                                    },\n                                    {\n                                        validator: feesValidator,\n                                        message: '最高收费要大于最低收费',\n                                    },\n                                ],\n                            },\n                        ]"
    }],
    staticClass: "marginright",
    attrs: {
      disabled: _vm.isshowbtn,
      type: "number"
    }
  })], 1), _c("span", [_vm._v("$")]), _c("div", {
    staticClass: "fees"
  }, [_vm._v("注：没有最低、最高收费，可不设置")])])], 1)]), _c("hr", {
    staticStyle: {
      "margin-bottom": "24px",
      height: "1px",
      border: "none",
      background: "#EFEFEF"
    }
  }), _c("div", [_c("div", {
    staticClass: "title",
    staticStyle: {
      "margin-bottom": "24px"
    }
  }, [_vm._v("支付失败手续费")]), _c("div", {
    staticClass: "title_2"
  }, [_vm._v(" 如果用户由于任何原因（如：网络问题、购买价款超出信用卡限额、信用卡存在不良记录）付款失败或付款被拒绝，则KNOWHERE有权自行收取相关费用 ")]), _c("div", {
    staticStyle: {
      display: "flex"
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "最低收费"
    }
  }, [_c("span", [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["paymentfailedmin", {
        rules: [{
          required: true,
          message: "请配置失败手续费！"
        }, {
          pattern: /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/,
          message: "请输入非负数！"
        }]
      }],
      expression: "[\n                            'paymentfailedmin',\n                            {\n                                rules: [\n                                    {\n                                        required: true,\n                                        message: '请配置失败手续费！',\n                                    },\n                                    {\n                                        pattern:\n                                            /^[+]{0,1}(\\d+)$|^[+]{0,1}(\\d+\\.\\d+)$/,\n                                        message: '请输入非负数！',\n                                    },\n                                ],\n                            },\n                        ]"
    }],
    staticClass: "marginright",
    attrs: {
      disabled: _vm.isshowbtn,
      type: "number"
    }
  })], 1), _c("span", [_vm._v("$ 或至多交易金额的   ")])]), _c("a-form-item", [_c("span", [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["paymentfailedmax", {
        rules: [{
          required: true,
          message: "请配置失败手续费！"
        }, {
          pattern: /^100$|^(\d|[1-9]\d)(\.\d+)*$/,
          message: "请输入0-100之间的数字！"
        }]
      }],
      expression: "[\n                            'paymentfailedmax',\n                            {\n                                rules: [\n                                    {\n                                        required: true,\n                                        message: '请配置失败手续费！',\n                                    },\n                                    {\n                                        pattern:\n                                            /^100$|^(\\d|[1-9]\\d)(\\.\\d+)*$/,\n                                        message: '请输入0-100之间的数字！',\n                                    },\n                                ],\n                            },\n                        ]"
    }],
    staticClass: "marginright",
    attrs: {
      disabled: _vm.isshowbtn,
      type: "number"
    }
  })], 1), _c("span", [_vm._v("%")])])], 1)]), _c("div", {
    staticClass: "combinbtn"
  }, [_c("a-button", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isshowbtn,
      expression: "!isshowbtn"
    }],
    staticStyle: {
      border: "none",
      "margin-right": "26px",
      "background-color": "#f5f5f5"
    },
    attrs: {
      size: "large"
    },
    on: {
      click: function click($event) {
        return _vm.handlecancel();
      }
    }
  }, [_vm._v("取消")]), _c("a-button", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isshowbtn,
      expression: "isshowbtn"
    }, {
      name: "hasPermission",
      rawName: "v-hasPermission",
      value: ["generalconfigurationbuyer:btn:edit"],
      expression: "['generalconfigurationbuyer:btn:edit']"
    }],
    attrs: {
      type: "primary",
      size: "large"
    },
    on: {
      click: function click($event) {
        return _vm.handleupdate();
      }
    }
  }, [_vm._v("修改")]), _c("a-button", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isshowbtn,
      expression: "!isshowbtn"
    }, {
      name: "hasPermission",
      rawName: "v-hasPermission",
      value: ["generalconfigurationbuyer:btn:submit"],
      expression: "['generalconfigurationbuyer:btn:submit']"
    }],
    attrs: {
      type: "primary",
      size: "large"
    },
    on: {
      click: function click($event) {
        return _vm.handleAdd();
      }
    }
  }, [_vm._v("确定")])], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };