import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-form", {
    attrs: {
      form: _vm.createForm,
      labelCol: {
        style: "width: 90px;text-align:right;margin-right:10px;font-family: PingFang SC;font-style: normal;font-weight: 500;font-size: 14px;color: #232323;"
      }
    }
  }, [_c("div", [_c("div", {
    staticClass: "title",
    staticStyle: {
      "margin-bottom": "24px"
    }
  }, [_vm._v("时效配置")]), _c("div", {
    staticClass: "content"
  }, [_c("a-form-item", [_c("span", {
    staticClass: "marginright"
  }, [_vm._v("1、订单结算周期：当商品鉴定为真后")]), _c("span", [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["ordersettlement", {
        rules: [{
          required: true,
          message: "请配置订单结算周期！"
        }, {
          pattern: /^[1-9]\d*$/,
          message: "请输入正整数！"
        }]
      }],
      expression: "[\n                            'ordersettlement',\n                            {\n                                rules: [\n                                    {\n                                        required: true,\n                                        message: '请配置订单结算周期！',\n                                    },\n                                    {\n                                        pattern: /^[1-9]\\d*$/,\n                                        message: '请输入正整数！',\n                                    },\n                                ],\n                            },\n                        ]"
    }],
    staticClass: "marginright",
    attrs: {
      disabled: _vm.isshowbtn,
      type: "number"
    }
  })], 1), _c("span", {
    staticClass: "marginright"
  }, [_vm._v("小时内，平台将贷款结算给卖家")])]), _c("a-form-item", [_c("span", {
    staticClass: "marginright"
  }, [_vm._v("2、卖家发货时间")]), _c("span", [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["deliverytime", {
        rules: [{
          required: true,
          message: "请配置卖家发货时长！"
        }, {
          pattern: /^[1-9]\d*$/,
          message: "请输入正整数！"
        }]
      }],
      expression: "[\n                            'deliverytime',\n                            {\n                                rules: [\n                                    {\n                                        required: true,\n                                        message: '请配置卖家发货时长！',\n                                    },\n                                    {\n                                        pattern: /^[1-9]\\d*$/,\n                                        message: '请输入正整数！',\n                                    },\n                                ],\n                            },\n                        ]"
    }],
    staticClass: "marginright",
    attrs: {
      disabled: _vm.isshowbtn,
      type: "number"
    }
  })], 1), _c("span", {
    staticClass: "marginright"
  }, [_vm._v("小时内，逾期未发货，将被判定为超时未发货，交易将会被关闭")])]), _c("a-form-item", [_c("span", {
    staticClass: "marginright"
  }, [_vm._v("3、卖家收货时间")]), _c("span", [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["receivingtime", {
        rules: [{
          required: true,
          message: "请配置卖家收货时长！"
        }, {
          pattern: /^[1-9]\d*$/,
          message: "请输入正整数！"
        }]
      }],
      expression: "[\n                            'receivingtime',\n                            {\n                                rules: [\n                                    {\n                                        required: true,\n                                        message: '请配置卖家收货时长！',\n                                    },\n                                    {\n                                        pattern: /^[1-9]\\d*$/,\n                                        message: '请输入正整数！',\n                                    },\n                                ],\n                            },\n                        ]"
    }],
    staticClass: "marginright",
    attrs: {
      disabled: _vm.isshowbtn,
      type: "number"
    }
  })], 1), _c("span", {
    staticClass: "marginright"
  }, [_vm._v("小时内，逾期未收货，平台将自动收货")])]), _c("a-form-item", [_c("span", {
    staticClass: "marginright"
  }, [_vm._v("4、物流更新时间")]), _c("span", [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["logisticsupdatetime", {
        rules: [{
          required: true,
          message: "物流更新时长不可为空！"
        }, {
          pattern: /^[1-9]\d*$/,
          message: "请输入正整数！"
        }]
      }],
      expression: "[\n                            'logisticsupdatetime',\n                            {\n                                rules: [\n                                    {\n                                        required: true,\n                                        message: '物流更新时长不可为空！',\n                                    },\n                                    {\n                                        pattern: /^[1-9]\\d*$/,\n                                        message: '请输入正整数！',\n                                    },\n                                ],\n                            },\n                        ]"
    }],
    staticClass: "marginright",
    attrs: {
      disabled: _vm.isshowbtn,
      type: "number"
    }
  })], 1), _c("span", {
    staticClass: "marginright"
  }, [_vm._v("小时内，逾期未更新，将判定为虚假发货，交易将会被关闭")]), _c("div", {
    staticClass: "fees",
    staticStyle: {
      "margin-left": "120px"
    }
  }, [_vm._v(" 注：周末和法定节假日期间，发货时间、收货时间、物流更新时间将依据放假时间往后顺延 ")])])], 1)]), _c("hr", {
    staticStyle: {
      "margin-bottom": "24px",
      height: "1px",
      border: "none",
      background: "#efefef"
    }
  }), _c("div", [_c("div", {
    staticClass: "title",
    staticStyle: {
      "margin-bottom": "24px"
    }
  }, [_vm._v("服务费")]), _c("div", [_c("div", {
    staticClass: "title_2",
    staticStyle: {
      "margin-bottom": "24px"
    }
  }, [_vm._v(" 1、技术服务费 (Service Fee) ")]), _c("div", {
    staticClass: "content"
  }, [_c("a-form-item", {
    attrs: {
      label: "商品金额的"
    }
  }, [_c("span", [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["servicerate", {
        rules: [{
          required: true,
          message: "技术服务费不可为空！"
        }, {
          pattern: /^100$|^(\d|[1-9]\d)(\.\d+)*$/,
          message: "请输入0-100之间的数字！"
        }]
      }],
      expression: "[\n                                'servicerate',\n                                {\n                                    rules: [\n                                        {\n                                            required: true,\n                                            message: '技术服务费不可为空！',\n                                        },\n                                        {\n                                            pattern:\n                                                /^100$|^(\\d|[1-9]\\d)(\\.\\d+)*$/,\n                                            message:\n                                                '请输入0-100之间的数字！',\n                                        },\n                                    ],\n                                },\n                            ]"
    }],
    staticClass: "marginright",
    attrs: {
      disabled: _vm.isshowbtn,
      type: "number"
    }
  })], 1), _c("span", [_vm._v("%")])]), _c("a-form-item", {
    attrs: {
      label: "最低收费"
    }
  }, [_c("span", [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["minservicecharge", {
        rules: [{
          pattern: /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/,
          message: "请输入非负数！"
        }, {
          validator: _vm.feesValidator,
          message: "最高收费要大于最低收费"
        }]
      }],
      expression: "[\n                                'minservicecharge',\n                                {\n                                    rules: [\n                                        {\n                                            pattern:\n                                                /^[+]{0,1}(\\d+)$|^[+]{0,1}(\\d+\\.\\d+)$/,\n                                            message: '请输入非负数！',\n                                        },\n                                        {\n                                            validator: feesValidator,\n                                            message:\n                                                '最高收费要大于最低收费',\n                                        },\n                                    ],\n                                },\n                            ]"
    }],
    staticClass: "marginright",
    attrs: {
      disabled: _vm.isshowbtn,
      type: "number"
    }
  })], 1), _c("span", [_vm._v("$")])]), _c("a-form-item", {
    attrs: {
      label: "最高收费"
    }
  }, [_c("span", [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["maxservicecharge", {
        rules: [{
          pattern: /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/,
          message: "请输入非负数！"
        }, {
          validator: _vm.feesValidator,
          message: "最高收费要大于最低收费"
        }]
      }],
      expression: "[\n                                'maxservicecharge',\n                                {\n                                    rules: [\n                                        {\n                                            pattern:\n                                                /^[+]{0,1}(\\d+)$|^[+]{0,1}(\\d+\\.\\d+)$/,\n                                            message: '请输入非负数！',\n                                        },\n                                        {\n                                            validator: feesValidator,\n                                            message:\n                                                '最高收费要大于最低收费',\n                                        },\n                                    ],\n                                },\n                            ]"
    }],
    staticClass: "marginright",
    attrs: {
      disabled: _vm.isshowbtn,
      type: "number"
    }
  })], 1), _c("span", [_vm._v("$")]), _c("div", {
    staticClass: "fees"
  }, [_vm._v("注：没有最低、最高收费，可不设置")])])], 1)]), _c("hr", {
    staticStyle: {
      "margin-bottom": "24px",
      height: "1px",
      border: "none",
      background: "#efefef"
    }
  }), _c("div", [_c("div", {
    staticClass: "title_2",
    staticStyle: {
      "margin-bottom": "24px"
    }
  }, [_vm._v(" 2、操作服务费 (Handing Fee) ")]), _c("div", {
    staticClass: "content"
  }, [_c("a-form-item", {
    attrs: {
      label: "通用费用"
    }
  }, [_c("span", [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["currency", {
        rules: [{
          required: true,
          message: "请填写操作服务费！"
        }, {
          pattern: /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/,
          message: "请输入非负数！"
        }]
      }],
      expression: "[\n                                'currency',\n                                {\n                                    rules: [\n                                        {\n                                            required: true,\n                                            message: '请填写操作服务费！',\n                                        },\n                                        {\n                                            pattern:\n                                                /^[+]{0,1}(\\d+)$|^[+]{0,1}(\\d+\\.\\d+)$/,\n                                            message: '请输入非负数！',\n                                        },\n                                    ],\n                                },\n                            ]"
    }],
    staticClass: "marginright",
    attrs: {
      disabled: _vm.isshowbtn,
      type: "number"
    }
  })], 1), _c("span", [_vm._v("$")])]), _c("a-form-item", {
    attrs: {
      label: "类目费用"
    }
  }, [_c("a-button", {
    attrs: {
      disabled: _vm.isshowbtn,
      type: "primary",
      size: "large"
    },
    on: {
      click: _vm.handleaddacatedory
    }
  }, [_vm._v("添加")])], 1), _vm._l(_vm.feesList, function (item, index) {
    return _c("div", {
      key: index
    }, [_c("div", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: item.status == 0,
        expression: "item.status == 0"
      }]
    }, [_c("a-form-item", {
      attrs: {
        label: "类目"
      }
    }, [_c("a-select", {
      staticStyle: {
        width: "262px",
        "margin-right": "20px"
      },
      attrs: {
        disabled: _vm.isshowbtn,
        "show-search": "",
        id: "parentcategoryid-".concat(index)
      },
      on: {
        change: function change(value, option) {
          return _vm.checkedid(value, index, item);
        }
      },
      model: {
        value: item.parentcategoryid,
        callback: function callback($$v) {
          _vm.$set(item, "parentcategoryid", $$v);
        },
        expression: "item.parentcategoryid"
      }
    }, _vm._l(_vm.parentcategoryList, function (item) {
      return _c("a-select-option", {
        key: item.id
      }, [_vm._v(_vm._s(item.name))]);
    }), 1), _c("a-select", {
      staticStyle: {
        width: "262px",
        "margin-right": "20px"
      },
      attrs: {
        disabled: _vm.isshowbtn,
        "show-search": ""
      },
      model: {
        value: item.categoryid,
        callback: function callback($$v) {
          _vm.$set(item, "categoryid", $$v);
        },
        expression: "item.categoryid"
      }
    }, _vm._l(_vm.secategoryList[index], function (item) {
      return _c("a-select-option", {
        key: item.id
      }, [_vm._v(_vm._s(item.name))]);
    }), 1), _c("span", {
      on: {
        click: function click($event) {
          return _vm.handledelete(index, item);
        }
      }
    }, [_vm._v("删除")])], 1), _c("a-form-item", {
      attrs: {
        label: "费用"
      }
    }, [_c("div", {
      staticStyle: {
        display: "flex"
      }
    }, [_c("span", [_c("a-input", {
      attrs: {
        disabled: _vm.isshowbtn,
        type: "number",
        id: "servicecharge-".concat(index)
      },
      on: {
        change: _vm.chagnefees
      },
      model: {
        value: item.servicecharge,
        callback: function callback($$v) {
          _vm.$set(item, "servicecharge", $$v);
        },
        expression: "item.servicecharge"
      }
    })], 1), _c("span", {
      staticStyle: {
        "margin-left": "10px",
        "margin-top": "7px"
      }
    }, [_vm._v("$")])])])], 1)]);
  })], 2)])]), _c("hr", {
    staticStyle: {
      "margin-bottom": "24px",
      height: "1px",
      border: "none",
      background: "#efefef"
    }
  }), _c("div", [_c("div", {
    staticClass: "title",
    staticStyle: {
      "margin-bottom": "24px"
    }
  }, [_vm._v("出售保证金")]), _c("div", [_c("div", {
    staticClass: "content"
  }, [_c("a-form-item", [_c("div", [_c("span", [_vm._v(" 为保证卖家按照KNOWHERE的规则进行经营，以及平台用户良好的使用体验，卖家需按照以下规则进行售卖 ")])]), _c("span", {
    staticClass: "marginright"
  }, [_vm._v("卖家每次出售商品，将收取")]), _c("span", [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["bondcharge", {
        rules: [{
          required: true,
          message: "技术服务费不可为空！"
        }, {
          pattern: /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/,
          message: "请输入非负数！"
        }]
      }],
      expression: "[\n                                'bondcharge',\n                                {\n                                    rules: [\n                                        {\n                                            required: true,\n                                            message: '技术服务费不可为空！',\n                                        },\n                                        {\n                                            pattern:\n                                                /^[+]{0,1}(\\d+)$|^[+]{0,1}(\\d+\\.\\d+)$/,\n                                            message: '请输入非负数！',\n                                        },\n                                    ],\n                                },\n                            ]"
    }],
    staticClass: "marginright",
    attrs: {
      disabled: _vm.isshowbtn,
      type: "number"
    }
  })], 1), _c("span", [_vm._v("$/件作为保证金。")])]), _c("a-form-item", [_c("span", {
    staticClass: "marginright"
  }, [_vm._v("因超时未发货，虚假发货，质检不通过，鉴定不通过，导致交易不成功时，将赔付")]), _c("span", [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["bondpaid", {
        rules: [{
          required: true,
          message: "技术服务费不可为空！"
        }, {
          pattern: /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/,
          message: "请输入非负数！"
        }]
      }],
      expression: "[\n                                'bondpaid',\n                                {\n                                    rules: [\n                                        {\n                                            required: true,\n                                            message: '技术服务费不可为空！',\n                                        },\n                                        {\n                                            pattern:\n                                                /^[+]{0,1}(\\d+)$|^[+]{0,1}(\\d+\\.\\d+)$/,\n                                            message: '请输入非负数！',\n                                        },\n                                    ],\n                                },\n                            ]"
    }],
    staticClass: "marginright",
    attrs: {
      disabled: _vm.isshowbtn,
      type: "number"
    },
    on: {
      blur: _vm.getBondpaid
    }
  })], 1), _c("span", [_vm._v("$/件保证金给买家")])])], 1)])]), _c("hr", {
    staticStyle: {
      "margin-bottom": "24px",
      height: "1px",
      border: "none",
      background: "#efefef"
    }
  }), _c("div", [_c("div", {
    staticClass: "title",
    staticStyle: {
      "margin-bottom": "24px"
    }
  }, [_vm._v("批量发货")]), _c("div", {
    staticClass: "content"
  }, [_c("a-form-item", [_c("span", {
    staticClass: "marginright"
  }, [_vm._v("商家可在平台进行批量发货，为保证交易的顺利进行，一个物流面单最多可发")]), _c("span", [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["maxorder", {
        rules: [{
          required: true,
          message: "请配置批量发货！"
        }, {
          pattern: /^0*([1-9]\d+|[2-9])$/,
          message: "请输入非负数！"
        }]
      }],
      expression: "[\n                            'maxorder',\n                            {\n                                rules: [\n                                    {\n                                        required: true,\n                                        message: '请配置批量发货！',\n                                    },\n                                    {\n                                        pattern:\n                                            /^0*([1-9]\\d+|[2-9])$/,\n                                        message: '请输入非负数！',\n                                    },\n                                ],\n                            },\n                        ]"
    }],
    staticClass: "marginright",
    attrs: {
      disabled: _vm.isshowbtn,
      type: "number"
    }
  })], 1), _c("span", {
    staticClass: "marginright"
  }, [_vm._v("个订单")])])], 1)]), _c("div", {
    staticClass: "combinbtn"
  }, [_c("a-button", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isshowbtn,
      expression: "!isshowbtn"
    }],
    staticStyle: {
      border: "none",
      "margin-right": "26px",
      "background-color": "#f5f5f5"
    },
    attrs: {
      size: "large"
    },
    on: {
      click: function click($event) {
        return _vm.handlecancel();
      }
    }
  }, [_vm._v("取消")]), _c("a-button", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isshowbtn,
      expression: "isshowbtn"
    }, {
      name: "hasPermission",
      rawName: "v-hasPermission",
      value: ["generalconfigurationseller:btn:edit"],
      expression: "['generalconfigurationseller:btn:edit']"
    }],
    attrs: {
      type: "primary",
      size: "large"
    },
    on: {
      click: function click($event) {
        return _vm.handleupdate();
      }
    }
  }, [_vm._v("修改")]), _c("a-button", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isshowbtn,
      expression: "!isshowbtn"
    }, {
      name: "hasPermission",
      rawName: "v-hasPermission",
      value: ["generalconfigurationseller:btn:submit"],
      expression: "['generalconfigurationseller:btn:submit']"
    }],
    attrs: {
      type: "primary",
      size: "large"
    },
    on: {
      click: function click($event) {
        return _vm.handleAdd();
      }
    }
  }, [_vm._v("确定")])], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };