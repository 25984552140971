var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-card", {
    staticStyle: {
      "overflow-x": "scroll"
    },
    attrs: {
      bordered: false
    }
  }, [_c("DTab", {
    staticStyle: {
      "overflow-x": "scroll"
    },
    attrs: {
      defaultIndex: _vm.defaultIndex,
      titles: _vm.titles,
      handleClick: _vm.handleClick,
      showNumb: false
    }
  }), _c("Withdrawrules", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.defaultIndex == 0,
      expression: "defaultIndex == 0"
    }]
  }), _c("Buyerrules", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.defaultIndex == 1,
      expression: "defaultIndex == 1"
    }]
  }), _c("Sellerrules", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.defaultIndex == 2,
      expression: "defaultIndex == 2"
    }]
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };